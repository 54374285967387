
import {View,ScrollView,Text, StyleSheet, Dimensions,TouchableOpacity,Image,useWindowDimensions,Linking} from 'react-native'
import { getAuth, signOut } from "firebase/auth";
import { LinearGradient } from 'expo-linear-gradient';
import { doc, setDoc, getFirestore, updateDoc,getDoc, collection, Firestore, getDocs,onSnapshot,  query,where, orderBy, limit} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { useEffect, useState } from 'react';
import {
    useFonts,
    OpenSans_300Light,
    OpenSans_300Light_Italic,
    OpenSans_400Regular,
    OpenSans_400Regular_Italic,
    OpenSans_600SemiBold,
    OpenSans_600SemiBold_Italic,
    OpenSans_700Bold,
    OpenSans_700Bold_Italic,
    OpenSans_800ExtraBold,
    OpenSans_800ExtraBold_Italic 
  } from '@expo-google-fonts/open-sans'
const Profile = ({navigation,route}) => {
    var SCREEN_WIDTH = 400;
    var SCREEN_HEIGHT = 900;
    let [fontsLoaded] = useFonts({
      OpenSans_400Regular,
      OpenSans_700Bold,
      OpenSans_800ExtraBold,
    });
  var image = ""
  const useStyle = () => {
    SCREEN_WIDTH = useWindowDimensions().width;
    SCREEN_HEIGHT = useWindowDimensions().height;
  const styles = StyleSheet.create({

    background: {
      width: SCREEN_WIDTH,
      height: SCREEN_HEIGHT,
      position:"absolute",
      top: 0,
      left: 0,
  
    },
    editprofilebutton:{
          
        margin:"2%",
        height: SCREEN_HEIGHT/17,
        elevation:5,
        zIndex:5,
        textAlign:'center',
        alignSelf:'center',
        justifyContent:'center',
        width: SCREEN_WIDTH/3,
        backgroundColor:"#f6a2d4",
        borderWidth:3,
        borderColor:'#f6c7e3',
        borderRadius: 10,
        shadowRadius:2,
        textAlignVertical:'center'
      },
      buttonText:{
          color:'white',
          fontFamily:'OpenSans_700Bold',
          fontSize:24

      },


    transparantIcon:{
       position:"absolute",
          width: SCREEN_WIDTH/7,
          height: SCREEN_HEIGHT/4.5,
          alignSelf: 'center',
        
          resizeMode: 'contain',
          opacity:"5%",
        },
  
  
  
    box: {
      height: SCREEN_HEIGHT/5,
      width: SCREEN_WIDTH/3,
      borderRadius: 5,
      elevation:5,
      zIndex:5,
      padding: 10,
      alignSelf: 'center',
      marginTop:"1%",
      margin:20,
      
    },
    header:{
        elevation:5,
        zIndex:5,
        fontFamily:'OpenSans_800ExtraBold',
        color:'white',
        padding:10,
        fontSize:SCREEN_WIDTH/24,
        alignSelf:'center'
      },
      text:{
        color:'white',
          fontFamily:'OpenSans_400Regular',
          fontSize:24,
          elevation:5,
          zIndex:5,
          textAlign:'center'

      },

      subheader:{
        elevation:5,
        zIndex:5,
        fontFamily:'OpenSans_700Bold',
        color:'white',
        padding:10,
        textDecorationLine:'underline',
        textDecorationStyle:'solid',
        textDecorationColor:'white',
        fontSize:SCREEN_WIDTH/32,
        alignSelf:'center'
      },

      charityheader:{
        fontSize:SCREEN_WIDTH/96,
        color:"white",
        padding: 0,

        fontFamily:'OpenSans_700Bold',
      },
      charitydescription:{
        fontSize:14,
        color:"white",
        padding: 0,
        fontFamily:'OpenSans_400Regular',
      }
   

  })

return { styles }
}

const { styles } = useStyle();

    const params = route.params;
    const firebaseConfig = {
        apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
        authDomain: "charitypoint.firebaseapp.com",
        databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "charitypoint",
        storageBucket: "charitypoint.appspot.com",
        messagingSenderId: "196189499670",
        appId: "1:196189499670:web:026458237765e7b3cfe037",
        measurementId: "G-WWZMG3JG6E",
        projectID: 'CharityPoint'
      };
    const [CharityNames,setCharityNames]  = useState([])
      const app = initializeApp(firebaseConfig);
      var [data,setData] = useState(['hello'])
      const Firestoredb = getFirestore(app);
      const supportList = []
      useEffect(() => {
        const q = query(collection(Firestoredb,'swipeCollection/'+params['id']+'/swipedCharitiesCollection'),where('Supported', '==', 1),orderBy('Timestamp'),limit(4));
     const unsubscribe = onSnapshot(q, (querySnapshot) => {
       querySnapshot.forEach((doc) => {
           supportList.push(JSON.parse(doc.data().CharityData))
           setData("reload")
           setData("reload2")
       });
     
       
          setCharityNames(supportList)
      })},[])

      console.log(CharityNames)
     
      for (let i=0;i<CharityNames.length;i++){
          var maincolor =""
          var secondarycolor=""
          if (CharityNames[i]['ukraine'] == "True") {
           maincolor = "#0057b7"
           secondarycolor = "#ffd700"
           image="ukraine"
          }
          else if (CharityNames[i]['children'] == "True") {
            maincolor = "#FFCD70"
            secondarycolor = "#FFCD70"
            image="children"
  
          }
          else if (CharityNames[i]['animals'] == "True") {
            maincolor = "#9BC383"
            secondarycolor = "#9BC383"
            image="animal"
  
          }
          else if (CharityNames[i]['health'] == "True") {
            maincolor = "#EF94CA"
            secondarycolor = "#EF94CA"
            image="health"
  
          }
          else if (CharityNames[i]['music'] == "True") {
            maincolor = "#DA585C"
            secondarycolor = "#DA585C"
            image="music"
  
          }
          else if (CharityNames[i]['religion'] == "True") {
            maincolor = "#FC834A"
            secondarycolor = "#FC834A"
            image="religion"
  
          }
         else{
           maincolor = "#949494"
           secondarycolor = "#949494"
           image="categories"
         }
         CharityNames[i]['maincolor'] = maincolor
         CharityNames[i]['secondarycolor'] = secondarycolor
         CharityNames[i]['image'] = image
            
        }
      
    return(
    <View>
         <Image
      source={require('./assets/profileImage.jpg')}
      style={styles.background}
    />
    <Text style={styles.header}>Profile</Text>
        <Text style={styles.text}>
        {params['name']} from {params['county']}
        </Text>

   
    <Text style={styles.text}>
                Has supported a total of {params['numsupported']} Charities 
        </Text> 



        <Text style={styles.subheader}>Charities last supported</Text>
        <View style={{flexDirection:'row',flexWrap:"wrap",justifyContent:'center'}}>
        {CharityNames.map((item,i) => (
                <TouchableOpacity key = {i} onPress={() => Linking.openURL("http://"+item.charity_contact_web) }>
                <LinearGradient
                colors={[item.maincolor,item.secondarycolor]}
                style={styles.box}
                locations={[0.5,0.5]}
              >
                  
                  <Image
          source={require('./assets/'+item.image+'.png')}
          style={styles.transparantIcon}
              />
               
      <Text style={styles.charityheader}>{item.charity_name}</Text>
      <Text style={styles.charitydescription}>{item.activities}</Text>
      <Text style={styles.charitydescription}>Based in {item.geographic_area_description}</Text>
      </LinearGradient>
      </TouchableOpacity>
        ))}
      </View>



        <View  style={{flexDirection:'row',justifyContent:'center'}}>
        <TouchableOpacity onPress={()=>navigation.navigate("EditProfile")} style={styles.editprofilebutton}>
            <Text style={styles.buttonText}>
                Edit Profile
            </Text>
        </TouchableOpacity>


        <TouchableOpacity onPress={()=>{         
            const auth = getAuth();
            signOut(auth).then(() => {
            navigation.navigate("Login")
            }).catch((error) => {
            console.log(error)
            });
        }} style={styles.editprofilebutton}>
            <Text style={styles.buttonText}>
                Sign Out
            </Text>
        </TouchableOpacity>
       
        </View>
    </View>
    )
}



export default Profile