import {useState} from "react";
import { StyleSheet, Text, View, TextInput, Button,  Image, Pressable,TouchableOpacity, Dimensions} from 'react-native';
import { getAuth, onAuthStateChanged,setPersistence,linkWithCredential,browserSessionPersistence,  signInWithEmailAndPassword, FacebookAuthProvider,GoogleAuthProvider,fetchSignInMethodsForEmail,  signInWithCredential, signInWithRedirect, signInWithPopup  } from "firebase/auth";
import { initializeApp } from "firebase/app";
import axios from "axios";
import { doc, getFirestore,setDoc, getDoc,addDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  useFonts,
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic 
} from '@expo-google-fonts/open-sans'


const Login = ({navigation}) => {

  let [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_800ExtraBold,
  });

  
  
  const firebaseConfig = {
    apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
    authDomain: "charitypoint.firebaseapp.com",
    databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "charitypoint",
    storageBucket: "charitypoint.appspot.com",
    messagingSenderId: "196189499670",
    appId: "1:196189499670:web:026458237765e7b3cfe037",
    measurementId: "G-WWZMG3JG6E",
    projectID: 'CharityPoint'
  };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);


  const [text, onChangeEmail] = useState(null);
  const [number, onChangePassword] = useState(null);
  const [errorMessage,onError] = useState(null);

  const auth = getAuth(app);

  onAuthStateChanged(auth,user=>{
    if (user){
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      var postcode = ""
      var name = ""
      var elo = 0
      var longitude = 0
      var county = ""
      var latitude = 0
      var numseen = 0
      var numsupported =0
      const id = user.uid
      const email = user.email
      const docRef = doc(db, "usersCollection", id);
      getDoc(docRef).then(docSnap => {
        if (docSnap.exists()) {
          elo = (docSnap.data().UserElo)
          postcode = (docSnap.data().postcode);
          name = (docSnap.data().name);
          numseen = (docSnap.data().numseen)
          numsupported = (docSnap.data().numsupported)
          longitude = (docSnap.data().longitude);
          county = (docSnap.data().county);
          latitude = (docSnap.data().latitude);
          navigation.navigate('home',{id,email,name,elo,county,longitude,latitude,numseen,numsupported})
        } else {
          console.log("No such document!");
        }
      })}})


  const signin = (email,password) => {
    setPersistence(auth, browserSessionPersistence).then(

    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {

      const user = userCredential.user;
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      var postcode = ""
      var name = ""
      var elo = 0
      var longitude = 0
      var county = ""
      var latitude = 0
      var numseen = 0
      var numsupported =0
      const id = user.uid
      const email = user.email
      const docRef = doc(db, "usersCollection", id);
      getDoc(docRef).then(docSnap => {
        if (docSnap.exists()) {
          elo = (docSnap.data().UserElo)
          postcode = (docSnap.data().postcode);
          name = (docSnap.data().name);
          
          longitude = (docSnap.data().longitude);
          county = (docSnap.data().county);
          latitude = (docSnap.data().latitude);
          navigation.navigate('home',{id,email,name,elo,county,longitude,latitude})
        } else {
          console.log("No such document!");
        }
      })
    })
    .catch((error) => {
      if (error.code==='auth/account-exists-with-different-credential'){
        var pendingCred = credentialFromError(error);
        console.log(pendingCred)
        const email = error.customData.email;
        fetchSignInMethodsForEmail(auth,email).then(function (methods) {
          console.log(methods)
    })}else if (error.code ==='auth/user-not-found'){
      onError("Invalid Email, please try again or create an account")
      
    }else if (error.code ==='auth/wrong-password'){
      onError("Invalid Password, please try again or create an account")
    }
    
    else{
      onError(String(error))
    }
  
  
  
  }))}

  const Facebooksignin = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;
      const db = getFirestore(app);
    var postcode = ""
    var name = ""
    var elo = 0
    var longitude = 0
    var county = ""
    var latitude = 0
    var numseen = 0
    var numsupported =0
    const id = user.uid
    console.log(id)
    const email = user.email
    const docRef = doc(db, "usersCollection", id);
    getDoc(docRef).then(docSnap => {
      if (docSnap.exists()) {
        elo = (docSnap.data().UserElo)
        postcode = (docSnap.data().postcode);
        name = (docSnap.data().name);
        
        longitude = (docSnap.data().longitude);
        county = (docSnap.data().county);
        latitude = (docSnap.data().latitude);
        navigation.navigate('home',{id,email,name,elo,county,longitude,latitude})
      } else {
        var postcode = prompt("Please enter your Postcode")
        axios.get("https://api.postcodes.io/postcodes/"+postcode).then(
      (response) => {
    
      const longitude = JSON.stringify(response.data.result.longitude)
      const latitude =  JSON.stringify(response.data.result.latitude)
      const admin_county =  JSON.stringify(response.data.result.admin_county)
        var name = prompt("Please enter your first name")

        const docRef = setDoc(doc(db,"usersCollection",user.uid),{
          uid: user.uid,
          name: name,
          postcode: postcode,
          UserElo : 1500,
          longitude: longitude,
          latitude: latitude,
          county :admin_county,
          numseen: 0,
          numsupported:0
          })

          getDoc(doc(db,"usersCollection",user.uid).then(docSnap => {
            if (docSnap.exists()) {
              elo = (docSnap.data().UserElo)
              postcode = (docSnap.data().postcode);
              name = (docSnap.data().name);
              
              longitude = (docSnap.data().longitude);
              county = (docSnap.data().county);
              latitude = (docSnap.data().latitude);
              navigation.navigate('home',{id,email,name,elo,county,longitude,latitude})
            }else{
              console("Something went wrong")
            }
      }
        )
    )})}})}).catch((error) => {
        console.log(error.message)
        if (error.code==='auth/account-exists-with-different-credential'){
          var pendingCred = FacebookAuthProvider.credentialFromError(error);
          console.log(pendingCred)
          const email = error.customData.email;
          fetchSignInMethodsForEmail(auth,email).then(function (methods) {
            console.log(methods)
          if (methods[0] === 'password'){
            const password = prompt("Enter your password");
            signInWithEmailAndPassword(auth,email, password).then(function(cred){

              linkWithCredential(cred.user,pendingCred);
            }
            )}
        }
            )}else if (error.code ==='auth/user-not-found'){
              onError("Invalid Email, please try again or create an account")
            }
            else{
              onError(String(error))
            }
      })}

      
  const Googlesignin = () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;
      const db = getFirestore(app);
    var name = ""
    var elo = 0
    var longitude = 0
    var county = ""
    var latitude = 0
    const id = user.uid
    console.log(id)
    const email = user.email
    const docRef = doc(db, "usersCollection", id);
    getDoc(docRef).then(docSnap => {
      if (docSnap.exists()) {
        elo = (docSnap.data().UserElo)
        postcode = (docSnap.data().postcode);
        name = (docSnap.data().name);
        
        longitude = (docSnap.data().longitude);
        county = (docSnap.data().county);
        latitude = (docSnap.data().latitude);
        navigation.navigate('home',{id,email,name,elo,county,longitude,latitude})
      } else {
        var postcode = prompt("Please enter your Postcode")
        axios.get("https://api.postcodes.io/postcodes/"+postcode).then(
      (response) => {
    
      const longitude = JSON.stringify(response.data.result.longitude)
      const latitude =  JSON.stringify(response.data.result.latitude)
      const admin_county =  JSON.stringify(response.data.result.admin_county)
        var name = prompt("Please enter your first name")

        const docRef = setDoc(doc(db,"usersCollection",user.uid),{
          uid: user.uid,
          name: name,
          postcode: postcode,
          UserElo : 1500,
          longitude: longitude,
          latitude: latitude,
          county :admin_county,
          numseen: 0,
          numsupported:0
          })

          getDoc(doc(db,"usersCollection",user.uid).then(docSnap => {
            if (docSnap.exists()) {
              elo = (docSnap.data().UserElo)
              postcode = (docSnap.data().postcode);
              name = (docSnap.data().name);
              
              longitude = (docSnap.data().longitude);
              county = (docSnap.data().county);
              latitude = (docSnap.data().latitude);
              navigation.navigate('home',{id,email,name,elo,county,longitude,latitude})
            }else{
              console("Something went wrong")
            }
      }
        )
    )})}})})
    


    .catch((error) => {
          console.log(error.message)
          if (error.code==='auth/account-exists-with-different-credential'){
            var pendingCred = GoogleAuthProvider.credentialFromError(error);
            console.log(pendingCred)
            const email = error.customData.email;
            fetchSignInMethodsForEmail(auth,email).then(function (methods) {
              console.log(methods)
            if (methods[0] === 'password'){
              const password = prompt("Enter your password");
              signInWithEmailAndPassword(auth,email, password).then(function(cred){
                linkWithCredential(cred.user,pendingCred);
              }
              )}
          }
              )}else if (error.code ==='auth/user-not-found'){
                onError("Invalid Email, please try again or create an account")
              }else if (error.code ==='auth/wrong-password'){
                onError("Invalid Password, please try again or create an account")
              }
          
              else{
                onError(String(error))
              
              }
        })}
  
  


  return (
    <View style={styles.container}>

      <Image
      source={require('./assets/loginImage.jpg')}
      style={styles.background}
    />
  <View style={styles.box}>
    <Image
      source={require('./assets/whiteLogo.png')}
      style={styles.logo}
    />
</View>
<View style={styles.roundedbox}>
  <Text style={styles.header}>ENTER YOUR ACCOUNT</Text>
  <Text style={{fontFamily:'OpenSans_400Regular', fontSize:32,color:'white'}}>Login using Social Networks</Text>
      <View style={{flexDirection:"row"}}> 
      <TouchableOpacity onPressIn={Facebooksignin}><Image style={styles.roundButton1}  source={require('./assets/f_logo.png')}/></TouchableOpacity> 
      <TouchableOpacity  onPressIn={Googlesignin}><Image style={styles.roundButton2}  source={require('./assets/google_logo.png')}/></TouchableOpacity>
      </View>
      <Text style={styles.or}>--------------------or---------------------</Text>
      <TextInput
        style={styles.input}
        onChangeText={onChangeEmail}
        value={text || ""}
        autoCorrect={false}
        maxLength={40}
        placeholder="Email"
        textContentType={"email"}
        autoComplete={"email"}
      />
      <TextInput
        style={styles.input}
        onChangeText={onChangePassword}
        value={number || ""}
        secureTextEntry={true}
        placeholder="Password"
        textContentType={"password"}
        autoComplete={"password"}
      />
      <View style={{flexDirection:"row"}}>
      <View style={{marginRight:20}}>
        <TouchableOpacity onPress={() => navigation.navigate('forgotPassword')}><Text style={styles.register}>Forgot Password?</Text></TouchableOpacity>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Signup')}><Text style={styles.register2}>Sign Up</Text></TouchableOpacity>
      </View>
    
      <TouchableOpacity
      style={styles.button}
     
      onPress={() => signin(text,number)}
      accessibilityLabel={"Login"}
      ><Text style={styles.loginText}>Login</Text></TouchableOpacity>
      <Text style={styles.errorText}>{errorMessage}</Text>
    </View>
</View>

   
  );

}
let ScreenHeight = Dimensions.get("window").height;
let ScreenWidth = Dimensions.get("window").width;




const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    resizeMode: 'contain',
    position:"absolute",
    top: 0,
    left: 0,
    


  },
  background: {
    width: "100%",
    height: "100%",
    position:"absolute",
    top: 0,
    left: 0,


  },
  logo:{
    flex:1,
    width: '100%',
    height: "100%",

    transform: [{ scaleX: .8 }, { scaleY: .8 }],
    resizeMode: 'contain',
  },

  box:{top:0,left:0,position:'absolute' ,marginTop:"1%", width: "100%",
  height: "10%",
  },
  roundedbox:{
 
    alignItems: 'center',
    height: "60%",
    width: "50%",
    borderRadius: 30,
    position: 'absolute',
    top: "15%",
    alignSelf:'center',
    elevation: 5,
    borderWidth:10,
    borderColor:'#7dc7c9',
    backgroundColor: '#57c5c7E9',
    shadowRadius:4,
    resizeMode: 'contain',
    

  },
  header:{
    fontFamily:'OpenSans_800ExtraBold',
    color:'white',
    padding:10,
    fontSize:48,
  
   
    

  },
  or:{
    width:"80%",
    fontSize:32,
    color:'white',
    fontFamily:'OpenSans_400Regular',
    alignSelf: 'center',
    textAlign:'center',
  },
  errorText:{
    fontSize:24,
    color:"red",
  },
  loginText:{
    fontSize:36,
    color:"white",
    padding: 0,
    fontFamily:'OpenSans_400Regular',
    
  },
  tinyLogo: {
    width:15,
    height:15,
    alignSelf: 'center',
    margin:4,
    resizeMode: 'contain',

  },

  input: {
    width: "80%",
    height: "10%",
    margin: "2%",
    backgroundColor:'#dad5d6',
    borderColor:'#555555',
    fontFamily:'OpenSans_400Regular',
    borderWidth:2,
    borderRadius: 30,
    padding: 10,
    fontSize:24,
  },

  rectangle: {
      resizeMode: 'contain',
    position: "absolute",
    width: ScreenWidth,
    height: (ScreenHeight-(ScreenHeight-100)),
    backgroundColor: "#3fccca",
    top:(ScreenHeight-"10%")
  },

  button:{
    margin:"2%",
    height: "10%",
    textAlign:'center',
    justifyContent:'center',
    width: "80%",
    backgroundColor:"#f6a2d4",
    borderWidth:3,
    borderColor:'#f6c7e3',
    borderRadius: 10,
    shadowRadius:2,

    textAlignVertical:'center'
  },
  roundButton1: {
    flex: 1,
    marginTop:5,
    width: 60,
    height: 60,


  },
  roundButton2: {
    marginLeft:10,
    marginTop:5,
    flex: 1,
    width: 60,
    height: 60,
    borderRadius: 100 / 2,

  },
  register:{
    textDecorationLine: 'underline',
    color:"#5b68fc",
    fontSize:32,
  },
  register2:{
    textDecorationLine: 'underline',
    color: "#895bfc",
    fontSize:32,
  },


});
export default Login