import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {useState} from "react";
import { StyleSheet, Text, View, TextInput, Button,  Image, Pressable,Dimensions,TouchableOpacity} from 'react-native';
import { initializeApp } from "firebase/app";
import {
  useFonts,
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic 
} from '@expo-google-fonts/open-sans'

const PasswordResetEmail = ({navigation}) => {
  let [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_800ExtraBold,
  });
  const firebaseConfig = {
    apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
    authDomain: "charitypoint.firebaseapp.com",
    databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "charitypoint",
    storageBucket: "charitypoint.appspot.com",
    messagingSenderId: "196189499670",
    appId: "1:196189499670:web:026458237765e7b3cfe037",
    measurementId: "G-WWZMG3JG6E",
    projectID: 'CharityPoint'
  };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app)
    const [email, onChangeEmail] = useState(null);
    const [error,setError] = useState(null)
const passwordReset = () => {    
console.log(email)
sendPasswordResetEmail(auth, email)
.then(() => {
  console.log(test)
  console.log('Password reset email sent successfully to',email);
  navigation.navigate('Login')
 })
 .catch((error) => {
  const errorCode = error.code;
  const errorMessage = error.message;
  setError("Please enter an email")
 });

}
    return(
        
<View style={styles.container}>


<Image
source={require('./assets/loginImage.jpg')}
style={styles.background}
/>
<View style={styles.box}>
<Image
source={require('./assets/whiteLogo.png')}
style={styles.logo}
/>
</View>
    <View style={styles.roundedbox}>
        <Text style={styles.header}>Forgot Password</Text>
        <Text style={styles.word}>Please Enter your Email Address</Text>
    <TextInput 
    autoCorrect={false}
    maxLength={30} placeholder="Email" 
    textContentType={"email"} 
    autoComplete={"email"} 
    style={styles.input}
    onChangeText={onChangeEmail}
    />
    <TouchableOpacity onPress={()=>passwordReset()} style={styles.button}><Text style={{color:"white",fontSize:24,justifyContent:'center'}}>Reset Password</Text></TouchableOpacity>
    <Text style={styles.errorText}>{error}</Text>
    <View style={{flexDirection:'row',width:'100%',justifyContent:'center'}}>
  <Text style={styles.gobacktext}>Don't need to reset? </Text>
  <TouchableOpacity onPress={() => navigation.navigate('Login')}><Text style={styles.goback}>Go back here</Text></TouchableOpacity>
  
  </View>
  </View>
    </View>

  

    )
}
let ScreenHeight = Dimensions.get("window").height;
let ScreenWidth = Dimensions.get("window").width;
const styles = StyleSheet.create({
    container: {
      width: "100%",
    height: "100%",
   
      resizeMode: 'contain',
      top: 0,
      left: 0,
      position: 'absolute',
      alignItems: 'center',
    },
    background: {
      width: "100%",
      height: "100%",
      position:"absolute",
      top: 0,
      left: 0,
  
  
    },
    logo:{
      flex:1,
      width: '100%',
      height: "100%",
  
      transform: [{ scaleX: .8 }, { scaleY: .8 }],
      resizeMode: 'contain',
    },
  
    box:{top:0,left:0,position:'absolute' ,marginTop:"1%", width: "100%",
    height: "10%",
    },

      header:{
    fontFamily:'OpenSans_800ExtraBold',
    color:'white',
    padding:10,
    fontSize:48,
  
   
   

  },  errorText:{
    fontSize:24,
    color:"red",
  },

    roundedbox: {
       
    alignItems: 'center',
    height: "40%",
    width: "50%",
    borderRadius: 30,
    position: 'absolute',
    top: "15%",
    alignSelf:'center',
    elevation: 5,
    borderWidth:10,
    borderColor:'#7dc7c9',
    backgroundColor: '#57c5c7E9',
    shadowRadius:4,
    resizeMode: 'contain',
    
        
      },
   
      input: {
        width: "80%",
        height: "15%",
        margin: "2%",
        backgroundColor:'#dad5d6',
        borderColor:'#555555',
        fontFamily:'OpenSans_400Regular',
        borderWidth:2,
        borderRadius: 30,
        padding: 10,
        fontSize:24,
      },
    
    word:{
        fontSize:24,
        color:"white",
        fontFamily:'OpenSans_400Regular',
        alignSelf:'center'
      
       },
    button:{
      margin:"2%",
      height: "15%",
      textAlign:'center',
      justifyContent:'center',
      width: "80%",
      backgroundColor:"#f6a2d4",
      borderWidth:3,
      borderColor:'#f6c7e3',
      borderRadius: 10,
      shadowRadius:2,

       },
       gobacktext:{
        color:"#895bfc",
        fontSize:32,
        fontFamily:'OpenSans_400Regular',
        textAlign:'center',
        marginBottom:"5%",
        marginLeft:"5%"
      },
      goback:{
        color:"#5b68fc",
        fontFamily:'OpenSans_400Regular',
        fontSize:32,
        marginBottom:"5%",
        textDecorationLine: 'underline'
      },
  });

export default PasswordResetEmail

