import { initializeApp } from "firebase/app";
import { LinearGradient } from 'expo-linear-gradient';
import {useState, useEffect, useRef} from "react"
import {View, Text, Animated,PanResponder, Dimensions, StyleSheet,TouchableOpacity,Linking,useWindowDimensions,Image} from 'react-native'
import { doc, setDoc, getFirestore, updateDoc,getDoc,deleteField } from "firebase/firestore";
import {
  useFonts,
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic 
} from '@expo-google-fonts/open-sans'


const firebaseConfig = {
    apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
    authDomain: "charitypoint.firebaseapp.com",
    databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "charitypoint",
    storageBucket: "charitypoint.appspot.com",
    messagingSenderId: "196189499670",
    appId: "1:196189499670:web:026458237765e7b3cfe037",
    measurementId: "G-WWZMG3JG6E",
    projectID: 'CharityPoint'
  };




const Favourites = ({route,navigation}) =>{
  let [fontsLoaded] = useFonts({
    OpenSans_300Light,
    OpenSans_400Regular,
    OpenSans_700Bold,
    OpenSans_800ExtraBold,
  });
  const useStyle = () => {
    var SCREEN_WIDTH = useWindowDimensions().width;
    var SCREEN_HEIGHT = useWindowDimensions().height;
    const styles = StyleSheet.create({
        background: {
          width: SCREEN_WIDTH,
          height: SCREEN_HEIGHT,
          position:"absolute",
          top: 0,
          left: 0,
      
        },
        transparantIcon:{
          position:"absolute",
          width: SCREEN_WIDTH/5,
          height: SCREEN_HEIGHT/5,
          alignSelf: 'center',
        
          resizeMode: 'contain',
          opacity:"5%",
        },

        box:{  
          height: SCREEN_HEIGHT/4,
          width: SCREEN_WIDTH/4,
          marginLeft:'10%',
          marginRight:'10%',
          marginTop:"2%",
          position: 'relative',
          alignSelf:'center',
          justifyContent:'center',
          elevation: 5,

          backgroundColor: '#57c5c7',
          borderRadius: 12,
          textAlign:'center',
          shadowRadius:3,
          borderWidth: 1,
          borderColor:"black",
      },
        searchheader:{
          fontSize:24,

          color:"white",
        padding: 0,
        fontFamily:'OpenSans_700Bold',
        },
        searchdescription:{
          fontSize:18,
        color:"white",
        padding: 0,
        fontFamily:'OpenSans_400Regular',
        },
        header:{
          elevation:5,
          zIndex:5,
          fontFamily:'OpenSans_800ExtraBold',
          color:'white',
          padding:10,
          fontSize:SCREEN_WIDTH/24,
          alignSelf:'center'
        },
    
         button:{
          backgroundColor:"#fff9db",
          borderWidth:3,
          borderColor:'#b6b2b3',
          width:"50%",
          height:"15%",
          margin:10,
          borderRadius:5,
          justifyContent:'center',
          textAlign:'center',
          alignSelf:'center',
          userSelect: "none",
         },
        errorText:{
          fontSize:24,color:"red"

        }

        
        })
    return { styles }
}

const { styles } = useStyle();



    const app = initializeApp(firebaseConfig);

const Firestoredb = getFirestore(app);
    const params = route.params;
const favourite = []
var [error,setError] = useState(null)

const [test,setTest] = useState([])
var image = ""
useEffect(() => {
    getDoc(doc(Firestoredb,"favouritesCollection",params['id'])).then(data=>{
        try {
            const list = []
            //const a = Object.values(data.data())
            const values = Object.values(data.data())
            for (let i=0;i<values.length;i++){
                const test = JSON.parse(values[i])
                favourite.push(test)
            }
            for (let i=0;i<favourite.length;i++){
              var maincolor =""
              var secondarycolor=""
              if (favourite[i]['ukraine'] == "True") {
               maincolor = "#0057b7"
               secondarycolor = "#ffd700"
               image="ukraine"
              }
              else if (favourite[i]['children'] == "True") {
                maincolor = "#FFCD70"
                secondarycolor = "#FFCD70"
                image="children"
      
              }
              else if (favourite[i]['animals'] == "True") {
                maincolor = "#9BC383"
                secondarycolor = "#9BC383"
                image="animal"
      
              }
              else if (favourite[i]['health'] == "True") {
                maincolor = "#EF94CA"
                secondarycolor = "#EF94CA"
                image="health"
      
              }
              else if (favourite[i]['music'] == "True") {
                maincolor = "#DA585C"
                secondarycolor = "#DA585C"
                image="music"
      
              }
              else if (favourite[i]['religion'] == "True") {
                maincolor = "#FC834A"
                secondarycolor = "#FC834A"
                image="religion"
      
              }
             else{
               maincolor = "#949494"
               secondarycolor = "#949494"
               image="categories"
             }
             favourite[i]['maincolor'] = maincolor
             favourite[i]['secondarycolor'] = secondarycolor
             favourite[i]['image'] = image
            
            }
        console.log(favourite)
        setTest(favourite)
        }
        catch (error) {
            console.log(error)
            setError("You haven't favourited any charities yet.")

        }} 
)},[])
return(
    <View>
      <Text style={styles.header}>Favourites List</Text>
          <Image
    source={require('./assets/favouritesImage.jpg')}
    style={styles.background}
  />
      <View style={{flexDirection:'row',flexWrap:"wrap"}}>
        {test.map((item,i) => (
                 <TouchableOpacity key={i} onPress={() => Linking.openURL("http://"+item.charity_contact_web) }>
                      <LinearGradient
                      colors={[item.maincolor,item.secondarycolor]}
                      style={styles.box}
                      locations={[0.5,0.5]}
                    >
                      <Image
          source={require('./assets/'+item.image+'.png')}
          style={styles.transparantIcon}
              />
            <Text style={styles.searchheader}>{item.charity_name}</Text>
            <Text style={styles.searchdescription}>{item.activities}</Text>
            <Text style={styles.searchdescription}>Based in {item.geographic_area_description}</Text>

            <TouchableOpacity onPress={()=>{
                console.log(item.registered_charity_number)
                setDoc(doc(Firestoredb,"favouritesCollection",params['id']),{
                    [item.registered_charity_number] : deleteField()
                    }, { merge: true }).then("Done")

            }}style={styles.button}><Text>Remove</Text></TouchableOpacity>

            </LinearGradient>


            </TouchableOpacity>
        ))}
      </View>
        <Text>{error}</Text>
  
    </View>

  )
}



export default Favourites