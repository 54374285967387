import { initializeApp } from "firebase/app";

import {useState, useEffect, useRef} from "react"
import {View, Text, Animated,PanResponder, Dimensions, StyleSheet,TouchableOpacity,Linking, Image, useWindowDimensions } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { doc, setDoc, getFirestore, updateDoc,getDoc} from "firebase/firestore";
import { getDatabase,ref,onValue,update} from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import {
  useFonts,
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic 
} from '@expo-google-fonts/open-sans'






const multipleCategories =  ({route,navigation}) => {
  const params = route.params;
  navigation.setOptions({ title: params['type']+" Charities" })
  const [CharityNames,setCharityNames]  = useState([])
  let [fontsLoaded] = useFonts({
    OpenSans_300Light,
    OpenSans_400Regular,
    OpenSans_700Bold,
    OpenSans_800ExtraBold,
  });
  const firebaseConfig = {
    apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
    authDomain: "charitypoint.firebaseapp.com",
    databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "charitypoint",
    storageBucket: "charitypoint.appspot.com",
    messagingSenderId: "196189499670",
    appId: "1:196189499670:web:026458237765e7b3cfe037",
    measurementId: "G-WWZMG3JG6E",
    projectID: 'CharityPoint'
  };

  const app = initializeApp(firebaseConfig);
  var [data,setData] = useState(['hello'])
  const Firestoredb = getFirestore(app);
  const db = getDatabase(app);
  const analytics = getAnalytics(app);
  var image = "";
  var SCREEN_WIDTH = 400;
  var SCREEN_HEIGHT = 900;


  var elo = useRef().current
  const Placement = []
  const Charities = []
  useEffect(() => {
    elo = params['elo']
    fetch("https://europe-west1-charitypoint.cloudfunctions.net/algorithm?user="+params['id']+"&mode="+params['type'])
.then(res => res.json())
.then(out =>{

  for (let i=0;i<10;i++){
      Charities.push(out[i][0])
      Placement.push(out[i][2]) 

      setData("reload")
      setData("reload2")
    }
    setCharityNames(Charities)
  })},[])
  
const useStyle = () => {
    SCREEN_WIDTH = useWindowDimensions().width;
    SCREEN_HEIGHT = useWindowDimensions().height;
    const styles = StyleSheet.create({
        background: {
          width: SCREEN_WIDTH,
          height: SCREEN_HEIGHT,
          position:"absolute",
          top: 0,
          left: 0,
      
        },
        transparantIcon:{
          position:"absolute",
          width: SCREEN_WIDTH/3.5,
          height: SCREEN_HEIGHT/3.5,
          alignSelf: 'center',
        
          resizeMode: 'contain',
          opacity:"5%",
        },


        container: {
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        },
      
        leftArrow:{
          position:'absolute',
          top: 220,
          left:100,
          fontSize: 50,
          color:"#D1DADB"
        },
        leftDescription:{
          position:'absolute',
          top: 200,
          left:50,
          fontStyle: 'italic',
          fontSize: 12,
          color:"#D1DADB"
        },
        rightArrow:{
          position:'absolute',
          top: 220,
          right:100,
          fontSize: 50,
          color:"#D1DADB"
        },
        rightDescription:{
          position:'absolute',
          top: 200,
          right:50,
          fontStyle: 'italic',
          fontSize: 12,
          color:"#D1DADB"
        },
      
      
        titleText: {
          fontSize: 14,
          lineHeight: 24,
          fontWeight: "bold"
        },
        box: {
          height: 450,
          width: 900,
          padding: 10,
          position:'absolute',
          alignSelf: 'center',
          marginTop:"10%",
          borderRadius: 12,
          shadowRadius:3,
          borderWidth: 1,
          borderColor:"black",
          
        },
        box2: {
          height: 250,
          width: 450,
          backgroundColor:"#949494",
          borderRadius: 15,
          padding: 10,
          position:'absolute',
          alignSelf: 'center',
          marginTop:"10%"
        },
        charityname:{
          fontSize:32,
          color:'white',
          fontFamily:'OpenSans_700Bold',
          textAlign:'center',
          userSelect: "none",
        },
        charitydescription:{
          fontSize:24,
          color:'white',
          fontFamily:'OpenSans_400Regular',
          textAlign:'center',
          userSelect: "none",
          padding:5,
        },
        charityinfo:{
          fontSize:24,
          fontFamily:'OpenSans_400Regular',
          color:'white',
          textAlign:'center',
          userSelect: "none",
        },
        donateText:{
          fontSize:28,
          fontFamily:'OpenSans_700Bold',
          color:'black',
          textAlign:'center',
          userSelect: "none",
        },


        button:{
          
          backgroundColor:"#fff9db",
          borderWidth:3,
          borderColor:'#b6b2b3',
          width:"50%",
          height:"15%",
          margin:10,
          borderRadius:5,
          justifyContent:'center',
          textAlign:'center',
          alignSelf:'center',
          userSelect: "none",
        },

        favouritesButton:{
          
          backgroundColor:"#d9d9d9",
          borderWidth:6,
          borderColor:'#ffde59',
          width:SCREEN_HEIGHT/10,
          height:SCREEN_HEIGHT/10,
          top:SCREEN_HEIGHT -(SCREEN_HEIGHT/2.5),
          margin:10,
          borderRadius:100,
          justifyContent:'center',
          textAlign:'center',
          alignSelf:'center',
          userSelect: "none",
        },


        resetbutton:{
          backgroundColor:"#fff9db",
          borderWidth:3,
          borderColor:'#b6b2b3',
          width:SCREEN_HEIGHT/10,
          height:SCREEN_HEIGHT/10,
          top:SCREEN_HEIGHT -(SCREEN_HEIGHT/2.5),
          margin:10,
          borderRadius:100,
          justifyContent:'center',
          textAlign:'center',
          alignSelf:'center',
          userSelect: "none",
        },

        supportButton:{
          backgroundColor:"#fff9db",
          borderWidth:3,
          position:"absolute",
          borderColor:'#b6b2b3',
          width:SCREEN_HEIGHT/10,
          height:SCREEN_HEIGHT/10,
          top:SCREEN_HEIGHT -(SCREEN_HEIGHT/2.5),
          left:(SCREEN_WIDTH/1.5),
          margin:10,
          borderRadius:100,
          justifyContent:'center',
          textAlign:'center',
          alignSelf:'center',
          userSelect: "none",
        },
        
        notsupportButton:{
          backgroundColor:"#fff9db",
          position:"absolute",
          borderWidth:3,
          borderColor:'#b6b2b3',
          width:SCREEN_HEIGHT/10,
          height:SCREEN_HEIGHT/10,
          top:SCREEN_HEIGHT -(SCREEN_HEIGHT/2.5),
          right:(SCREEN_WIDTH/1.5),
          margin:10,
          borderRadius:100,
          justifyContent:'center',
          textAlign:'center',
          alignSelf:'center',
          userSelect: "none",
        }


      })
  
    return { styles }
}

const { styles } = useStyle();
    
  const pan = useRef(new Animated.ValueXY()).current;
  var currentIndex = useRef(0)

  const [value,setValue] = useState(0)
 


  const donateOpacity = pan.x.interpolate({
    inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
    outputRange: [0, 0, 3],
    extrapolate: 'clamp'
  })
  const notdonateOpacity = pan.x.interpolate({
    inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
    outputRange: [3, 0, 0],
    extrapolate: 'clamp'
  })
  const nextCardOpacity = pan.x.interpolate({
    inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
    outputRange: [1, 0, 1],
    extrapolate: 'clamp'
  })

  const nextCardScale = pan.x.interpolate({
    inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
    outputRange: [1, 0.8, 1],
    extrapolate: 'clamp'
  })



  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onPanResponderMove: (evt, gestureState) => {

        pan.setValue({ x: gestureState.dx, y: gestureState.dy })
        
      },
      onPanResponderRelease: (evt, gestureState) => {

        if (gestureState.dx > 120) {
          Animated.spring(pan, {
            toValue: { x: SCREEN_WIDTH + 100, y: gestureState.dy }
            
          }).start(() => {
            currentIndex.current = currentIndex.current + 1
            //console.log("right")
            const userELO =  elo
            //console.log(userELO)
            const charityELO = Charities[currentIndex.current-1]['elo'] 
            const   R1 = 10**(userELO/400)
            const   R2 = 10**(charityELO/400)
            const  E1 = R1/(R1+R2)
            const    E2 = R2/(R1+R2)
            const   S1 = 0
            const   S2 = 1
            const   K = 15
            const   r3 = Math.round(userELO+K*(S1-E1))
            const   r4 = Math.round(charityELO+K*(S2-E2))
            
            const charityNumber = Charities[currentIndex.current-1]['registered_charity_number']
            //console.log(r3,r4)

            const placementstring = Placement[currentIndex.current-1].toString()

            update(ref(db, placementstring),{ elo: r4 });
              onValue(ref(db, placementstring), (snapshot) => {
               // console.log(snapshot.val())
              })
              const numsupported = params['numsupported']+1
              const numseen = params['numseen']+1
            updateDoc(doc(Firestoredb,"usersCollection",params['id']),{
              UserElo : r3,
              numseen :numseen,
              numsupported:numsupported
              })
            const swipeRecord = Charities[currentIndex.current-1]
            swipeRecord['swipeResult'] = 1
            setDoc(doc(Firestoredb,"swipeCollection",params['id']),{
                [charityNumber] : JSON.stringify(swipeRecord),
  
                  },{ merge: true });
            elo = r3 
            console.log(elo)
            
                
            setValue(value => value + 1)
          
              pan.setValue({ x: 0, y: 0 })

          })}
         
        else if (gestureState.dx < -120) {
          Animated.spring(pan, {
            toValue: { x: -SCREEN_WIDTH - 100, y: gestureState.dy }
          }).start(() => {
            currentIndex.current = currentIndex.current + 1

            console.log("left")
                const userELO = elo
                console.log(userELO)
                const charityELO = Charities[currentIndex.current-1]['elo'] 
                const  R1 = 10**(userELO/400)
                const  R2 = 10**(charityELO/400)
                const    E1 = R1/(R1+R2)
                const    E2 = R2/(R1+R2)
                const   S1 = 1
                const   S2 = 0
                const    K = 5
                const   r3 = Math.round(userELO+K*(S1-E1))
                const   r4 = Math.round(charityELO+K*(S2-E2))
                
            const charityNumber = Charities[currentIndex.current-1]['registered_charity_number']
              //console.log(r3,r4)
              const placementstring = Placement[currentIndex.current-1].toString()
              update(ref(db, placementstring),{ elo: r4 });
              onValue(ref(db, placementstring), (snapshot) => {
                //console.log(snapshot.val())
              })
              const numseen = params['numseen']+1
             
              updateDoc(doc(Firestoredb,"usersCollection",params['id']),{
                UserElo : r3,
                numseen :numseen,
                
                })
              elo = r3 
              console.log(elo)
              const swipeRecord = Charities[currentIndex.current-1]
              swipeRecord['swipeResult'] = 0
              setDoc(doc(Firestoredb,"swipeCollection",params['id']),{
                  [charityNumber] : JSON.stringify(swipeRecord),
    
                    },{ merge: true });
              
            setValue(value => value + 1)
              pan.setValue({ x: 0, y: 0 })

          })
          }
        
        else {
          Animated.spring(pan, {
            toValue: { x: 0, y: 0 },
            friction: 4
          }).start()
        }
      }



    })
  ).current;

  return (
    <View>
          
    <View>

    <View>
     {CharityNames.map((item, i) => {
      
      if (i < currentIndex.current) {
        //console.log(i,currentIndex.current,"null")
        
        return null
      }
      else if (i == currentIndex.current) {
        //console.log(i,currentIndex.current,"equals")
        var maincolor =""
        var secondarycolor=""
        var website = CharityNames[i]['charity_contact_web']
        
        if (website.slice(0,7) !== "http://" && website.slice(0,8) !== "https://"){
          website = "https://"+website
    
        }


        if (CharityNames[i]['ukraine'] == "True") {
         maincolor = "#0057b7"
         secondarycolor = "#ffd700"
         image="ukraine"
        }
        else if (CharityNames[i]['health'] == "True") {
          maincolor = "#ffe477"
          secondarycolor = "#ffe477"
          image="health"

        }
        else if (CharityNames[i]['music'] == "True") {
          maincolor = "#DA585C"
          secondarycolor = "#DA585C"
          image="music"

        }
        else if (CharityNames[i]['religion'] == "True") {
          maincolor = "#FC834A"
          secondarycolor = "#FC834A"
          image="religion"

        }
        else if (CharityNames[i]['children'] == "True") {
          maincolor = "#FFCD70"
          secondarycolor = "#FFCD70"
          image="children"
        }
        else if (CharityNames[i]['animals'] == "True") {
          maincolor = "#9BC383"
          secondarycolor = "#9BC383"
          image="animal"

        }
       else{

         maincolor = "#949494"
         secondarycolor = "#949494"
         image="categories"
       }
        return (
          <View key={i}>
                  <Image
      source={require('./assets/'+image+'Image.jpg')}
      style={styles.background}
    />
      <Animated.View
        style={{
          
          transform: [{ translateX: pan.x }, { translateY: pan.y }]
        }}
        {...panResponder.panHandlers}
      >
            <LinearGradient
        colors={[maincolor,secondarycolor ]}
        style={styles.box}
        locations={[0.5,0.5]}
      >
        <Animated.View style={{userSelect: "none", opacity: donateOpacity, position: 'absolute',transform: [{ rotate: '10deg' }], top: 30, left: 700, zIndex: 1000 }}>
              <Text style={{ borderWidth: 3, borderColor: 'green', color: 'green', fontSize: 32, fontWeight: '800', padding: 10 }}>SUPPORT</Text>
            </Animated.View>
        <Animated.View style={{userSelect: "none", opacity: notdonateOpacity,transform: [{ rotate: '-10deg' }], position: 'absolute', top: 30, right: 700, zIndex: 1000 }}>
            <Text style={{ borderWidth: 3, borderColor: 'red', color: 'red', fontSize: 32, fontWeight: '800', padding: 10 }}>DISCARD</Text>
        </Animated.View>
        <Image
          source={require('./assets/'+image+'.png')}
          style={styles.transparantIcon}
        />
        <Text style={styles.charityname}>{CharityNames[i]['charity_name']}</Text>
        <Text style={styles.charitydescription}>{CharityNames[i][1]}</Text>
        <View style={{alignSelf:'center',flexDirection:'row'}}> 
        <Text style={styles.charityinfo}>{CharityNames[i]['activities']}</Text>
        </View>
        <View style={{alignSelf:'center',flexDirection:'row'}}> 
        <Text style={styles.charityinfo}>It mainly operates in {CharityNames[i]['geographic_area_description']}</Text>
        </View>
        <View style={{alignSelf:'center',flexDirection:'row'}}> 
        <Text style={styles.charityinfo}>Founded in: {CharityNames[i]['date_of_registration'].slice(0,4)}</Text>
        </View>


        
        <TouchableOpacity onPress={() => Linking.openURL(website)} style={styles.button}>
        <Text style={styles.donateText}>Donate</Text>
        </TouchableOpacity>
        
   
        
        </LinearGradient>
        
      </Animated.View>
      <View>
        
      </View>
      <TouchableOpacity onPress={() => {

setDoc(doc(Firestoredb,"favouritesCollection",params['id']),{
  [CharityNames[i]['registered_charity_number']] : JSON.stringify(CharityNames[i]),

    },{ merge: true });

     }
          
        
        } style={styles.favouritesButton}>
        <Text>Add to Favourites</Text>
        </TouchableOpacity>


      </View>
      )
}
else{
  
  //console.log(i,currentIndex.current,"else")
return (
  
  <Animated.View
    key={i} style={[{
      opacity: nextCardOpacity,
      transform: [{ scale: nextCardScale }],
      height: SCREEN_HEIGHT, width: SCREEN_WIDTH,  position: 'absolute'
    }]}>
      
        <View style={styles.box2} />
      </Animated.View>
)}
    }).reverse()}

    </View>
    
    </View>

    <TouchableOpacity style={[styles.resetbutton,{ backgroundColor: "#EF94CA"}]} onPress={()=> location.reload()}>
        <Text style={{color:'white'}}>Reset Search</Text>
        </TouchableOpacity>
        
    <Text style={styles.leftArrow}>←</Text>
    <Text style={styles.leftDescription}>Swipe left to dismiss</Text>
    <Text style={styles.rightArrow}>→ </Text>
    <Text style={styles.rightDescription}>Swipe right to support</Text>

    </View>
  )
}




  export default multipleCategories