import Login from "./login"
import SignUp from "./signup"
import {Categories} from "./categories"
import CardScreen from "./cardscreen";
import Profile from "./profile";
import Search from "./search"
import PasswordResetEmail from "./forgotPassword";
import { NavigationContainer} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import EditProfile from "./editProfile";
import Multiplecategories from "./multiplecategories";
import Favourites from "./favourites";
import Button  from "react-native";


const MyTheme = {
  colors: {
    card: '#57c5c7',
    text: 'white',
    border: '#57c5c7',
  },
};

const ProfileScreen = ({route})=>{
  const profStack = createNativeStackNavigator();
  const params = route.params;
  return(

  <profStack.Navigator screenOptions={{ headerShown: false}}>
      <profStack.Screen name="ProfileMain" component={Profile} initialParams={params}/>
      <profStack.Screen name="EditProfile" component={EditProfile} initialParams={params}/>
  </profStack.Navigator>
  )
  }
  
  
const CategoriesScreen = ({route})=>{
    const catStack = createNativeStackNavigator();
    const params = route.params;
    return(
  
    <catStack.Navigator screenOptions={{ headerShown: false}}>
        <catStack.Screen name="CategoriesMain" component={Categories} initialParams={params}/>
        <catStack.Screen name="Multiplecategories" component={Multiplecategories} initialParams={params}/>
    </catStack.Navigator>
    )
    }
    
const Home = ({route}) => {
  const Tab = createBottomTabNavigator();
  const params = route.params;
  const firebaseConfig = {
    apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
    authDomain: "charitypoint.firebaseapp.com",
    databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "charitypoint",
    storageBucket: "charitypoint.appspot.com",
    messagingSenderId: "196189499670",
    appId: "1:196189499670:web:026458237765e7b3cfe037",
    measurementId: "G-WWZMG3JG6E",
    projectID: 'CharityPoint'
  };
  
  return (

        <Tab.Navigator  screenOptions={({ route  }) => ({
         
          
   tabBarIcon: ({ focused, color, size }) => {
     let iconName;
     if (route.name === 'Home') {
       iconName = 
         'home';
     } else if (route.name === 'Categories') {
       iconName = 'folder';
     }
     else if (route.name === 'Profile') {
       iconName = 'human-handsup';
     }
     else if (route.name === 'Search') {
      iconName = 'magnify';
    }
    else if (route.name === 'Favourites') {
      iconName = 'heart';
    }
     return <MaterialCommunityIcons name={iconName} size={size} color={color} />;
   },
   tabBarActiveTintColor: '#FFCD70',
   tabBarInactiveTintColor: 'white',

   
 })}>
        <Tab.Screen name="Home" options={{tabBarLabelPosition:'below-icon'}} component={CardScreen} initialParams={params} />
        <Tab.Screen name="Categories"  options={{tabBarLabelPosition:'below-icon'}} component={CategoriesScreen} initialParams={params}/>
        <Tab.Screen name="Search"  options={{tabBarLabelPosition:'below-icon'}} component={Search} initialParams={params}/>
        <Tab.Screen name="Favourites"   options={{tabBarLabelPosition:'below-icon'}} component={Favourites} initialParams={params}/>
        <Tab.Screen name="Profile"   options={{tabBarLabelPosition:'below-icon'}} component={ProfileScreen} initialParams={params}/>
      </Tab.Navigator>
    

  );
}

const auth = () => {
  const Stack = createNativeStackNavigator();
  return(
  <NavigationContainer theme={MyTheme}>
  <Stack.Navigator screenOptions={{ headerShown: false}}>
      <Stack.Screen name="Login" component={Login}/>
      <Stack.Screen name="Signup" component={SignUp} />
      <Stack.Screen name="forgotPassword" component={PasswordResetEmail} />
      <Stack.Screen name="home" component = {Home}/>
  </Stack.Navigator>
  </NavigationContainer>
); 
}

export default auth

