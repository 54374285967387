import { initializeApp } from "firebase/app";
import { getAuth,  createUserWithEmailAndPassword} from "firebase/auth";
import axios from 'axios';
import { doc, setDoc, getFirestore } from "firebase/firestore";
import {useState} from 'react'
import { StyleSheet, Text, View,  TouchableOpacity,TextInput, Image, Dimensions  } from 'react-native';
import {
  useFonts,
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic 
} from '@expo-google-fonts/open-sans'

const SignUp = ({navigation}) => {
  const firebaseConfig = {
    apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
    authDomain: "charitypoint.firebaseapp.com",
    databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "charitypoint",
    storageBucket: "charitypoint.appspot.com",
    messagingSenderId: "196189499670",
    appId: "1:196189499670:web:026458237765e7b3cfe037",
    measurementId: "G-WWZMG3JG6E",
    projectID: 'CharityPoint'
  };
  let [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_800ExtraBold,
  });
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const [error,setError] = useState(null)
  
  const SIGNUP = (name,postcode,email,password) => {
    const auth = getAuth(app);
          createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      
      axios.get("https://api.postcodes.io/postcodes/"+postcode).then(
        (response) => {
      
        const longitude = JSON.stringify(response.data.result.longitude)
        const latitude =  JSON.stringify(response.data.result.latitude)
        const admin_county =  JSON.stringify(response.data.result.admin_county)
        const user = userCredential.user;
        const docRef = setDoc(doc(db,"usersCollection",userCredential.user.uid),{
         uid: userCredential.user.uid,
         name: name,
         postcode: postcode,
         UserElo : 1500,
         longitude: longitude,
         latitude: latitude,
         county :admin_county,
         numseen: 0,
         numsupported:0
         })
         navigation.navigate('Login')

      }
      ).catch((error) => {const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorCode, errorMessage)});



    })
    .catch((error) => {
     const errorCode = error.code;
     const errorMessage = error.message;
     setError(errorCode, errorMessage)
    
   }); 
   }
   const [name, onChangeName] = useState(null);
   const [postcode, onChangePostcode] = useState(null);
   const [email, onChangeEmail] = useState(null);
   const [password, onChangePassword] = useState(null);
  

return(
  <View style={styles.container}> 
  <Image
  source={require('./assets/loginImage.jpg')}
  style={styles.background}
/>
<View style={styles.box}>
<Image
  source={require('./assets/whiteLogo.png')}
  style={styles.logo}
/>
</View>
 
  <View style={styles.roundedbox}>
  <Text style={styles.header}>CREATE AN ACCOUNT</Text>
  <Text style={styles.line}>----------------------------------</Text>
  <TextInput 
          autoCorrect={false}
          maxLength={15} placeholder="First Name" 
        textContentType={"firstname"} 
        autoComplete={"firstname"} 
        style={styles.input}
        onChangeText={onChangeName}
        />
  <TextInput   
        autoCorrect={false}
        maxLength={7} placeholder="Postcode" 
        textContentType={"postcode"} 
        autoComplete={"postcode"}
        autoCapitalize={"characters"} 
        style={styles.input}
        onChangeText={onChangePostcode}
        />

  <TextInput 
        autoCorrect={false}
        maxLength={30} placeholder="Email" 
        textContentType={"email"} 
        autoComplete={"email"} 
        style={styles.input}
        onChangeText={onChangeEmail}
        />

  <TextInput
          style={styles.input}
          secureTextEntry={true}
          placeholder="Password"
          textContentType={"password"}
          autoCorrect={false}
          autoComplete={"password"}
          onChangeText={onChangePassword}
  />



  <TouchableOpacity onPress={() => {SIGNUP(name,postcode,email,password)}} style={styles.button}><Text style={styles.word}>Register</Text></TouchableOpacity>
  <Text style={styles.error}>{error}</Text>
  <View style={{flexDirection:'row',width:'100%',justifyContent:'center'}}>
  <Text style={styles.signintext}>Already have an account? </Text>
  <TouchableOpacity onPress={() => navigation.navigate('Login')}><Text style={styles.signin}>Sign in here</Text></TouchableOpacity>
  </View>
  </View>
</View>

)
}

const styles = StyleSheet.create({

  signin:{
  fontSize:32,
  color:"#5b68fc",
  fontFamily:'OpenSans_400Regular',
  textAlign:'center',
  textDecorationLine: 'underline',
  marginBottom:"5%",
  },

  word:{ fontSize:36,
    color:"white",
    padding: 0,
    fontFamily:'OpenSans_400Regular'},

  signintext:{ color:"#895bfc",
  fontFamily:'OpenSans_400Regular',
  fontSize:32,
  marginBottom:"5%",
  marginLeft:"5%"
  },



  container: {
    width: "100%",
    height: "100%",
    resizeMode: 'contain',
    position:"absolute",
    top: 0,
    left: 0,
    

  },
  background: {
    width: "100%",
    height: "100%",
    position:"absolute",
    top: 0,
    left: 0,


  },
  logo:{
    flex:1,
    width: '100%',
    height: "100%",

    transform: [{ scaleX: .8 }, { scaleY: .8 }],
    resizeMode: 'contain',
  },

  box:{top:0,left:0,position:'absolute' ,marginTop:"1%", width: "100%",
  height: "10%",
  },
  roundedbox:{
 
    alignItems: 'center',
    height: "60%",
    width: "50%",
    borderRadius: 30,
    position: 'absolute',
    top: "15%",
    alignSelf:'center',
    elevation: 5,
    borderWidth:10,
    borderColor:'#7dc7c9',
    backgroundColor: '#57c5c7E9',
    shadowRadius:4,
    resizeMode: 'contain',
    

  },
  header:{
    fontFamily:'OpenSans_800ExtraBold',
    color:'white',
    padding:10,
    fontSize:48,
  
  },

  line:{
    width:"80%",
    fontSize:32,
    color:'white',
    fontFamily:'OpenSans_400Regular',
    alignSelf: 'center',
    textAlign:'center',
  },
  errorText:{
    fontSize:24,
    color:"red",
  },


  input: {
    width: "80%",
    height: "15%",
    margin: "1%",
    backgroundColor:'#dad5d6',
    borderColor:'#555555',
    fontFamily:'OpenSans_400Regular',
    borderWidth:2,
    borderRadius: 30,
    padding: 10,
    fontSize:24,
  },

  button:{
    margin:"2%",
    height: "10%",
    textAlign:'center',
    justifyContent:'center',
    width: "80%",
    backgroundColor:"#f6a2d4",
    borderWidth:3,
    borderColor:'#f6c7e3',
    borderRadius: 10,
    shadowRadius:2,

    textAlignVertical:'center'
  },

});
//  <Text style={{textAlign:'right',marginRight:10,marginTop:10, fontSize:12,textDecorationLine:'underline',color:"#5b68fc",}}  onPress={()=> navigation.navigate("Terms and Conditions")}>Accept Privacy Policy</Text>
export default SignUp