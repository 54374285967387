
import {Text,View, StyleSheet,Image,Dimensions,ScrollView,TouchableOpacity,useWindowDimensions } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient';
import {
  useFonts,
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic 
} from '@expo-google-fonts/open-sans'
import { withTheme } from 'react-native-elements';

const Categories = ({route,navigation}) => {
  const params = route.params;
  let [fontsLoaded] = useFonts({
    OpenSans_300Light,
    OpenSans_400Regular,
    OpenSans_700Bold,
    OpenSans_800ExtraBold,
  });
  
  const useStyle = () => {
    var SCREEN_WIDTH = useWindowDimensions().width;
    var SCREEN_HEIGHT = useWindowDimensions().height;
    const styles = StyleSheet.create({
        background: {
          width: SCREEN_WIDTH,
          height: SCREEN_HEIGHT,
          position:"absolute",
          top: 0,
          left: 0,
      
        },
        transparantIcon:{
          position:"absolute",
          width: SCREEN_WIDTH/3.5,
          height: SCREEN_HEIGHT/9,
          alignSelf: 'center',
        
          resizeMode: 'contain',
          opacity:"5%",
        },


        container: {
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        },
      
  boxText:{
    fontFamily:'OpenSans_700Bold',
    fontSize:24,
    color:'white'

  },

  box:{  
    height: SCREEN_HEIGHT/9,
    width: SCREEN_WIDTH/2,
    marginTop:"2%",
    position: 'relative',
    alignSelf:'center',
    justifyContent:'center',
    elevation: 5,
    backgroundColor: '#57c5c7',
    borderRadius: 12,
    textAlign:'center',
    shadowRadius:3,
    borderWidth: 1,
    borderColor:"black",
},
});
  
    return { styles }
}

const { styles } = useStyle();


  return(
    <View>
    <Image
    source={require('./assets/categoriesImage.jpg')}
    style={styles.background}
  />
    <ScrollView style={{height:"100%"}}>
      {/*Ukraine*/}
      <TouchableOpacity onPress={()=>navigation.navigate('Multiplecategories',{type:'ukraine'})} >
      <LinearGradient
        colors={['#0057b7', '#ffd700']}
        style={styles.box}
        locations={[0.5,0.5]}
      >
                <Image
          source={require('./assets/ukraine.png')}
          style={styles.transparantIcon}
        />
      <Text style={styles.boxText}>Charities that provide support for Ukraine</Text>
      </LinearGradient>
      </TouchableOpacity>

        {/*Animals*/}
        <TouchableOpacity onPress={()=>navigation.navigate('Multiplecategories',{type:'animals'})} >
      <LinearGradient
        colors={["#9BC383","#9BC383"]}
        style={styles.box}
      >
         <Image
          source={require('./assets/animal.png')}
          style={styles.transparantIcon}
        />
      <Text style={styles.boxText}>Animal Charities and Organisations</Text>
      </LinearGradient>
      </TouchableOpacity>

      {/*Health*/}
      <TouchableOpacity onPress={()=>navigation.navigate('Multiplecategories',{type:'health'})} >
      <LinearGradient
        colors={["#EF94CA","#EF94CA"]}
        style={styles.box}
      >
         <Image
          source={require('./assets/health.png')}
          style={styles.transparantIcon}
        />
      <Text style={styles.boxText}>Health Charities and Organisations</Text>
      </LinearGradient>
      </TouchableOpacity>

      {/*Mental Health*/}
      <TouchableOpacity onPress={()=>navigation.navigate('Multiplecategories',{type:'children'})} >
      <LinearGradient
        colors={["#FFCD70","#FFCD70"]}
        style={styles.box}
      >
        <Image
          source={require('./assets/children.png')}
          style={styles.transparantIcon}
        />
      <Text style={styles.boxText}>Childrens Charities and Organisations</Text>
      </LinearGradient>
      </TouchableOpacity>

      {/*Religion*/}
      <TouchableOpacity onPress={()=>navigation.navigate('Multiplecategories',{type:'religion'})} >
      <LinearGradient
        colors={["#FC834A","#FC834A"]}
        style={styles.box}
      >
         <Image
          source={require('./assets/religion.png')}
          style={styles.transparantIcon}
        />
      <Text style={styles.boxText}>Religious Charities and Organisations</Text>
      </LinearGradient>
      </TouchableOpacity>

      {/*Music*/}
      <TouchableOpacity onPress={()=>navigation.navigate('Multiplecategories',{type:'music'})} >
      <LinearGradient
        colors={["#DA585C","#DA585C"]}
        style={styles.box}
      >
         <Image
          source={require('./assets/music.png')}
          style={styles.transparantIcon}
        />
      <Text style={styles.boxText}>Musical Charities and Organisations</Text>
      </LinearGradient>
      </TouchableOpacity>
    </ScrollView>
    </View>
)}



  



export {Categories}