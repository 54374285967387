import { View,Text,TextInput,ScrollView,StyleSheet,TouchableOpacity,Linking,Image,useWindowDimensions } from "react-native"
import {useState} from 'react'
import { LinearGradient } from 'expo-linear-gradient';
import {
  useFonts,
  OpenSans_300Light,
  OpenSans_300Light_Italic,
  OpenSans_400Regular,
  OpenSans_400Regular_Italic,
  OpenSans_600SemiBold,
  OpenSans_600SemiBold_Italic,
  OpenSans_700Bold,
  OpenSans_700Bold_Italic,
  OpenSans_800ExtraBold,
  OpenSans_800ExtraBold_Italic 
} from '@expo-google-fonts/open-sans'

const firebaseConfig = {
  apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
  authDomain: "charitypoint.firebaseapp.com",
  databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "charitypoint",
  storageBucket: "charitypoint.appspot.com",
  messagingSenderId: "196189499670",
  appId: "1:196189499670:web:026458237765e7b3cfe037",
  measurementId: "G-WWZMG3JG6E",
  projectID: 'CharityPoint'
};



const Search = () =>{
  var SCREEN_WIDTH = 400;
  var SCREEN_HEIGHT = 900;
  let [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_700Bold,
    OpenSans_800ExtraBold,
  });
var image = ""
  const useStyle = () => {
    SCREEN_WIDTH = useWindowDimensions().width;
    SCREEN_HEIGHT = useWindowDimensions().height;
  const styles = StyleSheet.create({

    background: {
      width: SCREEN_WIDTH,
      height: SCREEN_HEIGHT,
      position:"absolute",
      top: 0,
      left: 0,
  
    },

    transparantIcon:{
       position:"absolute",
          width: SCREEN_WIDTH/7,
          height: SCREEN_HEIGHT/4.5,
          alignSelf: 'center',
        
          resizeMode: 'contain',
          opacity:"5%",
        },
  
  
  
    box: {
      height: SCREEN_HEIGHT/4,
      width: SCREEN_WIDTH/3,
      borderRadius: 5,
      elevation:5,
      zIndex:5,
      padding: 10,
      alignSelf: 'center',
      marginTop:"1%",
      margin:20,
      
    },
      
      input: {
        alignSelf:'center',
        width:"90%",
        marginTop:"1%",
        height: SCREEN_HEIGHT/20,
        borderWidth: 1,
        shadowRadius:2,
        elevation:5,
        zIndex:5,
        selectionColor:"#5b68fc",


        backgroundColor:'#dad5d6',
    borderColor:'#555555',
    fontFamily:'OpenSans_400Regular',
    borderWidth:2,
    borderRadius: 30,
    padding: 10,
    fontSize:SCREEN_WIDTH/64,



      },
     
      header:{
        elevation:5,
        zIndex:5,
        fontFamily:'OpenSans_800ExtraBold',
        color:'white',
        padding:10,
        fontSize:SCREEN_WIDTH/24,
        alignSelf:'center'
      },
      searchText:{
        fontSize:SCREEN_WIDTH/64,
        color:"white",
        padding: 0,
        fontFamily:'OpenSans_400Regular',
        
      },
      button:{
        margin:"2%",
        height: SCREEN_HEIGHT/17,
        elevation:5,
        zIndex:5,
        textAlign:'center',
        alignSelf:'center',
        justifyContent:'center',
        width: "80%",
        backgroundColor:"#f6a2d4",
        borderWidth:3,
        borderColor:'#f6c7e3',
        borderRadius: 10,
        shadowRadius:2,
    
        textAlignVertical:'center'
      },
      searchheader:{
        fontSize:SCREEN_WIDTH/96,
        color:"white",
        padding: 0,
        fontFamily:'OpenSans_700Bold',
      },
      searchdescription:{
        fontSize:14,
        color:"white",
        padding: 0,
        fontFamily:'OpenSans_400Regular',
      }


  })

return { styles }
}

const { styles } = useStyle();

  const [search, onChangeSearch] = useState(null);

  const [SearchResults,setSearchResults]  = useState([])

  const searchresult = []

  const SearchResult =()=>{
  
    fetch('https://europe-west1-charitypoint.cloudfunctions.net/search?search='+search)
    .then(res => res.json())
    .then(out =>{
      for (let i=0;i<out.length;i++){
        var maincolor =""
        var secondarycolor=""
        if (out[i]['ukraine'] == "True") {
         maincolor = "#0057b7"
         secondarycolor = "#ffd700"
         image="ukraine"
        }
        else if (out[i]['children'] == "True") {
          maincolor = "#FFCD70"
          secondarycolor = "#FFCD70"
          image="children"

        }
        else if (out[i]['animals'] == "True") {
          maincolor = "#9BC383"
          secondarycolor = "#9BC383"
          image="animal"

        }
        else if (out[i]['health'] == "True") {
          maincolor = "#EF94CA"
          secondarycolor = "#EF94CA"
          image="health"

        }
        else if (out[i]['music'] == "True") {
          maincolor = "#DA585C"
          secondarycolor = "#DA585C"
          image="music"

        }
        else if (out[i]['religion'] == "True") {
          maincolor = "#FC834A"
          secondarycolor = "#FC834A"
          image="religion"

        }
       else{
         maincolor = "#949494"
         secondarycolor = "#949494"
         image="categories"
       }
          out[i]['maincolor'] = maincolor
          out[i]['secondarycolor'] = secondarycolor
          out[i]['image'] = image
          searchresult.push(out[i])
      }
      setSearchResults(searchresult)
    })}

  
  return(
    <ScrollView >
                
        <Image
      source={require('./assets/searchImage.jpg')}
      style={styles.background}
    />
      <Text style={styles.header}>Enter Search Term</Text>
      <TextInput
        autoCorrect={false}
        maxLength={50} placeholder="Search Term" 
      style={styles.input}
      onChangeText={onChangeSearch}
      />
      <TouchableOpacity onPress={()=>SearchResult()}style={styles.button}><Text style={styles.searchText}>Search</Text></TouchableOpacity>
      
      <View style={{flexDirection:'row',flexWrap:"wrap",justifyContent:'center'}}>
        {SearchResults.map((item,i) => (
                 <TouchableOpacity onPress={() => Linking.openURL("http://"+item.charity_contact_web) }>
                      <LinearGradient
                      colors={[item.maincolor,item.secondarycolor]}
                      style={styles.box}
                      locations={[0.5,0.5]}
                    >
                      <Image
          source={require('./assets/'+item.image+'.png')}
          style={styles.transparantIcon}
              />
            <Text style={styles.searchheader}>{item.charity_name}</Text>
            <Text style={styles.searchdescription}>{item.activities}</Text>
            <Text style={styles.searchdescription}>Based in {item.geographic_area_description}</Text>
            </LinearGradient>
            </TouchableOpacity>
        ))}
      </View>
    
  
      </ScrollView>

  )
}

export default Search

