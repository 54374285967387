import { View,Text,TextInput,TouchableOpacity,StyleSheet } from "react-native"
import { initializeApp } from "firebase/app";
import { doc, setDoc, getFirestore, updateDoc } from "firebase/firestore";
import axios from "axios";
import {useState} from "react"
const EditProfile = ({route,navigation}) => {
  const firebaseConfig = {
    apiKey: "AIzaSyDVmo8yYHRU0m2rVXzMUQhNXvaTUyeO7V0",
    authDomain: "charitypoint.firebaseapp.com",
    databaseURL: "https://charitypoint-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "charitypoint",
    storageBucket: "charitypoint.appspot.com",
    messagingSenderId: "196189499670",
    appId: "1:196189499670:web:026458237765e7b3cfe037",
    measurementId: "G-WWZMG3JG6E",
    projectID: 'CharityPoint'
  };

  const app = initializeApp(firebaseConfig);
  const Firestoredb = getFirestore(app);
    const params = route.params;
    ;

    const [password,onChangePassword] = useState(null)
    const [postcode,onChangePostcode] = useState(null)
    const [name,onChangeName] = useState(null)
    const [nameresult,onChangeNameResult] = useState(null)
    const [postcoderesult,onChangePostcodeResult] = useState(null)

    const ChangePostcode = () =>{
      axios.get("https://api.postcodes.io/postcodes/"+postcode).then(
      (response) => {
      const longitude = JSON.stringify(response.data.result.longitude)
      const latitude =  JSON.stringify(response.data.result.latitude)
      const admin_county =  JSON.stringify(response.data.result.admin_county)
      updateDoc(doc(Firestoredb,"usersCollection",params['id']),{
        postcode : postcode,
        longitude: longitude,
        latitude : latitude,
        county: admin_county,
        }).then(
      onChangePostcodeResult("Postcode Change Successful")
        )
    })}

    const ChangeName = () =>{
      updateDoc(doc(Firestoredb,"usersCollection",params['id']),{
        name : name,
        }).then(
          onChangeNameResult("First Name Change Successful")
          
            )
        }
  
    return(
        <View>
            <Text style={styles.header}>Edit Profile</Text>
            <Text style={styles.subheader}>Change First Name</Text>
            <TextInput 
          autoCorrect={false}
          maxLength={15} placeholder="First Name" 
        textContentType={"firstname"} 
        autoComplete={"firstname"} 
        style={styles.input}
        onChangeText={onChangeName}
        />
            <TouchableOpacity onPress={()=>ChangeName()} style={styles.button}><Text style={styles.buttontext}>Change First Name</Text></TouchableOpacity>
        <Text style={styles.successText}>{nameresult}</Text>
          
            <Text style={styles.subheader} >Change Postcode</Text>
            <TextInput  autoCorrect={false}
        maxLength={7} placeholder="Postcode" 
        textContentType={"postcode"} 
        autoComplete={"postcode"}
        autoCapitalize={"characters"} 
        style={styles.input}
        onChangeText={onChangePostcode}/>
            <TouchableOpacity  onPress={()=>ChangePostcode()} style={styles.button}><Text style={styles.buttontext}>Change Postcode</Text></TouchableOpacity>
            <Text style={styles.successText}>{postcoderesult}</Text>

            <TouchableOpacity style={styles.button} onPress={()=>location.reload()}><Text style={styles.buttontext}>Return to Profile</Text></TouchableOpacity>


        </View>
    )
}

const styles = StyleSheet.create({
header:{
    fontWeight:"Bold",
    fontSize:24,
    color:"#895bfc",
    alignSelf:'center'
},
subheader:{
    fontSize:16,
    fontWeight:'600',
    alignSelf:'center'

},
button:{
    margin:"1%",
    height: "6%",
    fontSize:16,
    textAlign:'center',
    width: "40%",
    backgroundColor:"#EF94CA",
    borderRadius: 12,
    shadowRadius:2,
    alignSelf:'center',
  },
  buttontext:{
    color:"white",
  },
  input: {
    alignSelf:'center',
    width:"50%",
    height: "10%",
    borderWidth: 1,
    borderColor:"#c1bdbd",
    padding: 10,
    borderRadius: 12,
    shadowRadius:1,
    selectionColor:"#5b68fc",
  },
  successText:{
    fontSize:11,
    color:"green",
  },
})
export default EditProfile